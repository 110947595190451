<template>
  <v-row
    class="flex-column flex1 justify-self-center"
    style=" max-width: 400px;"
  >
    <v-row
      class="flex-column flex1"
      justify="center"
    >
      <v-container class="flex-1">
        <v-chip
          class="my-2"
          small
          :color="getColorEnv"
          v-if="env !== prodEnv"
        >{{ env }}</v-chip>
        <v-form>

          <div
            class="text-h4  font-weight-medium pb-0"
            v-text="$t('welcomeBack')"
          />
          <div
            class="text-body-2 pb-6"
            v-text="$t('enterCredentials')"
          />

          <v-text-field
            v-model="username"
            :rules="[rules.required]"
            name="email"
            :label="$t('email')"
            class="text-field-transparent no-border"
            solo
          />
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'hi-eye-slash' : 'hi-eye'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            :label="$t('password')"
            hint="At least 8 characters"
            class="text-field-transparent no-border "
            solo
            @click:append="showPassword = !showPassword"
          />

        </v-form>

        <v-card-actions>
          <v-layout
            class="py-2"
            align-center
            justify-center="true"
            row
            wrap
          >
            <v-btn
              :loading="isLoading"
              color="primary"
              large
              depressed
              block
              @click.native="login()"
            >{{ $t('logIn') }}</v-btn>
          </v-layout>
        </v-card-actions>
        <v-container class="pt-6">
          <v-row>
            <v-spacer />
            <v-btn
              style="text-transform: initial; letter-spacing: normal;"
              text
              elevation="0"
              @click="onReset"
            >{{ $t('forgotPassword') }}</v-btn>
          </v-row>
        </v-container>
      </v-container>
    </v-row>

    <v-container class="flex1 flex-column d-flex justify-end">
      <div>
        <!-- <v-row
          align="center"
          justify="center"
          class="my-0"
        >
          <span class="text-body-2 px-2">{{ $t('PoweredBy') }}</span>
          <img
            :src="getAppLogo"
            height="32"
          >

        </v-row> -->
        <v-row
          class="pb-4 "
          align="center"
          justify="center"
        >
          <!-- <span class="text-caption text-center gray--text"> {{ $t('creatingAccountTerms') }} <a :href="APP.terms">{{
            $t('termsAndconditions') }}</a> {{ $t('and') }}
            <a :href="APP.privacyPolicy"> {{ $t('privacyPolicy') }}</a></span> -->


        </v-row>
      </div>
    </v-container>
  </v-row>
</template>

<script>

import * as Actions from "../../store/constants.js";
import loadingView from "./LoadingView";
require("vue2-animate/dist/vue2-animate.min.css");
import swiftioLogoFull from "../../assets/swiftio-logo-full.svg";
import swiftioLogoFullLight from "../../assets/swiftio-logo-full-light.svg";
import LogComponent from "./LogComponent.vue";
import { mapGetters } from 'vuex';
import { APP, NODE_ENV, PRODUCTION, STAGING } from '../../api/constants.js';


export default {
  components: { loadingView, LogComponent, },
  props: {
    onReset: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      APP,
      showLogin: true,
      showPassword: false,
      avatarSize: "80px",
      username: "",
      password: "",
      success: false,
      isLoading: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters"
      },
      swiftioLogoFull,
      swiftioLogoFullLight,
      env: NODE_ENV,
      prodEnv: PRODUCTION,
    };
  },
  computed: {
    ...mapGetters(['isDark', 'isDev']),
    getColorEnv() {
      if (this.env === STAGING) return 'warning'
      return 'success'
    },
    getAvatar() {
      var user = this.$store.getters.getCurrentUser;
      var image = this.demo[user.name];
      if (image === undefined || image === null) {
        image = this.demo["Nicolas"];
      }
      return image;
    },
    getUser() {
      var user = this.$store.getters.getCurrentUser;
      if (user !== undefined && user !== null) {
        return user;
      }
      return { name: "Nicolas" };
    },
    getLogState() {
      return this.$store.getters.getLogState;
    },
  },
  methods: {
    initApplication() {
      this.showLogin = false;
      var payload = {
        name: this.getUser.name,
        password: this.password
      }
      // this.$store.commit("setCurrentUser", payload);
      setTimeout(this.initApp, 500);
    },
    resetApplication() {
      this.$store.dispatch(Actions.DELETE_OBJECT_CONTEXT);
    },
    initApp() {
      this.$store.commit(Actions.SET_APP_ROUTER, this.$router);
      this.$store.dispatch("initApplication");
    },
    toggleLogs() {
      this.$store.commit("toggleLogs");
    },
    async login() {
      this.isLoading = true;
      this.$store.commit("hideAlert");
      this.$store.dispatch('getAccessTokenToken', { username: this.username, password: this.password }).then(success => {
        this.initApp()
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      });

    }
  }
};
</script>


<style>
.bkg {
  /* background-image: url('~@/assets/solar-bkg.jpg'); */
  background-size: cover;
}
</style>