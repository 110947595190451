/**
 * Alert Context
 */

import { getGuid, isEmpty, isTrue } from "../api/utilities";
import { CREATE_OPERATION_CONTAINER } from "./constants";

const initState = {
  title: "",
  showDialog: false,
  showCloseButton: true,
  viewConfig: "",
  object: null,
  relatedObject: null,
  saveFunction: null,
  cancelFunction: null,
  startPosition: null,
  operationId: null,
  origin: null,
  dialog: {
    show: null,
  },
};

export default {
  state: initState,
  getters: {
    getViewConfigForActionDialog: (state, getters) => {
      return state.viewConfig;
    },
    getShowCloseButton: (state, getters) => {
      return state.showCloseButton;
    },
    getObjectForActionDialog: (state, getters) => {
      return state.object;
    },
    getActionShowDialog: (state, getters) => {
      return state.dialog;
    },
    getOperationIdForActionDialog: (state, getters) => {
      return state.operationId;
    },
    getOriginForActionDialog: (state, getters) => {
      return state.origin;
    },
  },
  mutations: {
    showActionDialog(state, payload) {
      const { viewConfig, object, operationId, origin, hideCloseButton } =
        payload;
      if (isTrue(hideCloseButton)) {
        state.showCloseButton = false;
      } else {
        state.showCloseButton = true;
      }
      state.viewConfig = viewConfig;
      state.object = object;
      state.dialog.show = true;
      state.operationId = operationId;
      state.origin = origin;
    },
    hideActionDialog(state) {
      state.viewConfig = null;
      state.object = null;
      state.dialog.show = false;
      state.operationId = null;
      state.origin = null;
    },
  },
  actions: {
    async showActionDialog({ dispatch, commit, getters }, payload) {
      if (isEmpty(payload.operationId)) {
        const operationId = getGuid();
        var config = getters.getObjectConfigForType(payload.objectType);
        var payloadOperation = {
          object: payload.object,
          config: config,
          id: operationId,
        };
        payload.operationId = operationId;
        await dispatch(CREATE_OPERATION_CONTAINER, payloadOperation);
      }
      commit("showActionDialog", payload);
    },
  },
};
