import { isEmpty, isTrue } from "../../api/utilities.js";
import * as ObjectApi from "../../api/objectApi";
import Vue from "vue";
import {
  OBJECT_CONFIG,
  SYNC_GROUP_CORE_REST,
  SYNC_GROUP_CORE_FIELDS,
  SYNC_GROUP_CORE_OBJECTS,
  SYNCHRONISATION,
  OPERATION,
  TRANSPORT_MAIN,
  TRANSPORT_DOMAIN_ITEM,
  TRANSPORT_ITEM,
  CLIENT_ATTRIBUTES,
  FIELD_CONFIG,
  ALERT_TYPE_INFO,
  ALERT_TYPE_ERROR,
  SYNC_GROUP_CORE_SYNC_GROUP,
} from "../../store/constants";
import {
  TRANSPORT_ITEM_STATUS_CANCELLED,
  TRANSPORT_ITEM_STATUS_FAILED,
  TRANSPORT_ITEM_STATUS_OPEN,
  TRANSPORT_ITEM_STATUS_SENT,
  TRANSPORT_ITEM_STATUS_SUCCESS,
} from "./transportItemScripts.js";
import { TENANT } from "../../api/constants.js";
export const TRANSPORT_STATUS_COMPLETED = "S002";

const MAX_TRANSPORTS = 150;

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("try update)");
  if (
    isEmpty(object.startDate) &&
    !isEmpty(object.targetSystem) &&
    !isEmpty(object.transportEnv)
  ) {
    //FindlatestComplettiondate

    console.log("try update)");
    var transports =
      context.getters.getDataObjectsForObjectType(TRANSPORT_MAIN);
    var completedTransports = transports.filter(function (o) {
      return (
        o.targetSystem === object.targetSystem &&
        o.status === TRANSPORT_ITEM_STATUS_SUCCESS &&
        !isEmpty(o.completionDate) &&
        o.transportEnv === object.transportEnv
      );
    });
    var completedTransportsSorted = completedTransports.sort(function (a, b) {
      return new Date(b.completionDate) - new Date(a.completionDate);
    });
    if (completedTransportsSorted.length > 0) {
      Vue.set(object, "startDate", completedTransportsSorted[0].completionDate);
    }
  }
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  afterModify(objectConfig, object, null, context, options);
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  Vue.set(object, "targetSystem", TENANT);
  Vue.set(object, "status", TRANSPORT_ITEM_STATUS_OPEN);
  return object;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) {
  // console.log("Can Launch action for action", object);
  if (isEmpty(object)) {
    return false;
  }
  if (action === "transportAllItems") {
    if (
      object.status !== TRANSPORT_ITEM_STATUS_OPEN ||
      !hasOpenItems(object, context)
    ) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Transport cannot be launched in this status",
        });
      }
      return;
    }
  }
  if (action === "checkStatusOfTransport") {
    if (
      object.status === TRANSPORT_ITEM_STATUS_CANCELLED ||
      !hasItems(object, context)
    ) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Status check cannot be launched while cancelled",
        });
      }
      return;
    }
  }
  if (action === "getAllDomainItems") {
    if (
      object.status !== TRANSPORT_ITEM_STATUS_OPEN ||
      hasDomainItems(object, context)
    ) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Status check cannot be launched while cancelled",
        });
      }
      return;
    }
  }
  if (action === "getAllObjectsForAllDomainItems") {
    if (
      object.status !== TRANSPORT_ITEM_STATUS_OPEN ||
      !hasDomainItems(object, context)
    ) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Status check cannot be launched while cancelled",
        });
      }
      return;
    }
  }
  return true;
}

/***
 * Force full Synchronisation for object
 */
export async function getAllDomainItems(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  console.log("getAllDomainItems");
  var allObjects = context.getters.getDataObjectsForObjectType(OBJECT_CONFIG);

  var domainObjects = allObjects.filter(function (o) {
    return (
      (o.syncGroup === SYNC_GROUP_CORE_REST ||
        o.syncGroup === SYNC_GROUP_CORE_FIELDS ||
        o.syncGroup === SYNC_GROUP_CORE_OBJECTS) &&
      o.objectType !== SYNCHRONISATION &&
      o.objectType !== OPERATION &&
      o.objectType !== TRANSPORT_MAIN &&
      o.objectType !== TRANSPORT_DOMAIN_ITEM &&
      o.objectType !== TRANSPORT_ITEM &&
      o.objectType !== CLIENT_ATTRIBUTES
    );
  });

  for (var i = 0; i < domainObjects.length; i++) {
    var domainObject = domainObjects[i];
    var transportDomainItem = ObjectApi.newObjectWithGuid();
    var transportDomainItemConfig = context.getters.getObjectConfigForType(
      TRANSPORT_DOMAIN_ITEM,
    );
    transportDomainItemConfig.afterCreate(
      transportDomainItem,
      object,
      context,
      { objectType: TRANSPORT_MAIN },
    );
    Vue.set(transportDomainItem, "objectType", domainObject.objectType);
    Vue.set(transportDomainItem, "isActive", true);
    Vue.set(transportDomainItem, "startDate", object.startDate);

    var payload = {
      objectType: TRANSPORT_DOMAIN_ITEM,
      object: transportDomainItem,
    };
    await context.dispatch("saveObjectForObjectType", payload);
  }
}

function hasDomainItems(object, context) {
  var domainObjects = context.getters.getDataObjectsForObjectType(
    TRANSPORT_DOMAIN_ITEM,
  );
  var currentDomainObjects = domainObjects.filter(function (o) {
    return o.transport === object.Guid && isTrue(o.isActive);
  });
  return currentDomainObjects.length > 0;
}

function hasOpenItems(object, context) {
  var allItems = context.getters.getDataObjectsForObjectType(TRANSPORT_ITEM);
  var items = allItems.filter(function (o) {
    return (
      o.transport === object.Guid && o.status === TRANSPORT_ITEM_STATUS_OPEN
    );
  });
  return items.length > 0;
}

function hasItems(object, context) {
  var allItems = context.getters.getDataObjectsForObjectType(TRANSPORT_ITEM);
  var items = allItems.filter(function (o) {
    return o.transport === object.Guid;
  });
  return items.length > 0;
}

/***
 * getAllObjectsForAllDomainItems
 */
export async function getAllObjectsForAllDomainItems(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  context.commit("showAlert", {
    type: ALERT_TYPE_ERROR,
    message: "Do not use anymore! it does nothing",
  });
  return;
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Creating transport items...",
  });
  var now = new Date().toJSON();
  var domainObjects = context.getters.getDataObjectsForObjectType(
    TRANSPORT_DOMAIN_ITEM,
  );
  var currentDomainObjects = domainObjects.filter(function (o) {
    return o.transport === object.Guid && isTrue(o.isActive);
  });
  var transportdomainItemConfig = context.getters.getObjectConfigForType(
    TRANSPORT_DOMAIN_ITEM,
  );
  for (var i = 0; i < currentDomainObjects.length; i++) {
    var domainObject = currentDomainObjects[i];
    await context.dispatch("launchActionForObjectAndConfig", {
      action: "findObjectsForDomain",
      objectConfig: transportdomainItemConfig,
      object: domainObject,
      context: context,
      options: { showErrorMessage: false },
    });
  }
  Vue.set(object, "completionDate", now);
  await context.dispatch("saveObjectForObjectType", {
    objectType: TRANSPORT_MAIN,
    object: object,
  });
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Transport items created",
  });
}

/***
 * GetLastSynchronisationDate
 */
export async function fillLastSynchronisationDate(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  if (
    !canLaunchActionForObjectAndConfig(
      "GetLastSynchronisationDate",
      objectConfig,
      object,
      viewconfig,
      context,
      { showErrorMessage: true },
    )
  ) {
    return;
  }
  //Check if can be transported. Check on the status.

  var items = context.getters.getDataObjectsForObjectType(TRANSPORT_MAIN);
  var transportItems = items.points.sort(function (a, b) {
    var dateA = new Date(a.completionDate);
    var dateB = new Date(b.completionDate);
    return dateB - dateA;
  });
  var lastDate = transportItems.first;
  Vue.set(object, "completionDate", lastDate);
  await context.dispatch("saveObjectForObjectType", {
    objectType: TRANSPORT_MAIN,
    object: object,
  });
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Transport Completed!",
  });
}

/***
 * TransportAllItems
 */
export async function transportAllItems(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  if (
    !canLaunchActionForObjectAndConfig(
      "transportAllItems",
      objectConfig,
      object,
      viewconfig,
      context,
      { showErrorMessage: true },
    )
  ) {
    return;
  }
  //Check if can be transported. Check on the status.
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Starting Transport...",
  });
  var items = context.getters.getDataObjectsForObjectType(TRANSPORT_ITEM);
  var transportItems = items.filter(function (o) {
    return (
      o.transport === object.Guid &&
      (o.status === TRANSPORT_ITEM_STATUS_FAILED ||
        o.status === TRANSPORT_ITEM_STATUS_OPEN)
    );
  });
  var transportItemConfig =
    context.getters.getObjectConfigForType(TRANSPORT_ITEM);

  for (var i = 0; i < transportItems.length && i < MAX_TRANSPORTS; i++) {
    var transportItem = transportItems[i];
    await context.dispatch("launchActionForObjectAndConfig", {
      action: "transportItem",
      objectConfig: transportItemConfig,
      object: transportItem,
      context: context,
      options: { showErrorMessage: false },
    });
  }
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Transport Completed!",
  });
}

/***
 * checkStatusOfTransport
 */
export async function checkStatusOfTransport(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  if (
    !canLaunchActionForObjectAndConfig(
      "checkStatusOfTransport",
      objectConfig,
      object,
      viewconfig,
      context,
      { showErrorMessage: true },
    )
  ) {
    return;
  }
  //Check if can be transported. Check on the status.
  var items = context.getters.getDataObjectsForObjectType(TRANSPORT_ITEM);
  var transportItems = items.filter(function (o) {
    return o.transport === object.Guid;
  });
  var status = true;
  for (var i = 0; i < transportItems.length; i++) {
    var transportItem = transportItems[i];
    if (transportItem.status === TRANSPORT_ITEM_STATUS_FAILED) {
      status = TRANSPORT_ITEM_STATUS_FAILED;
      break;
    }
    if (transportItem.status === TRANSPORT_ITEM_STATUS_OPEN) {
      status = TRANSPORT_ITEM_STATUS_OPEN;
    }
    if (
      transportItem.status === TRANSPORT_ITEM_STATUS_SUCCESS &&
      (status !== TRANSPORT_ITEM_STATUS_OPEN ||
        status !== TRANSPORT_ITEM_STATUS_SENT)
    ) {
      status = TRANSPORT_ITEM_STATUS_SUCCESS;
    }
    if (
      transportItem.status === TRANSPORT_ITEM_STATUS_SENT &&
      status !== TRANSPORT_ITEM_STATUS_OPEN
    ) {
      status = TRANSPORT_ITEM_STATUS_SENT;
    }
  }
  if (object.status !== status) {
    Vue.set(object, "status", status);
    await context.dispatch("saveObjectForObjectType", {
      objectType: TRANSPORT_MAIN,
      object: object,
    });
  }
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Status Checked!",
  });
}
