<template>
  <v-container
    fill-height
    fluid
    class="card d-flex flex-column h-screen flex-nowrap"
  >
    <!-- <div class="splash-background px-2 px-md-10">
      <img
        :src="splash"
        width="100%"
        height="100%"
      >
    </div> -->
    <div class="d-flex justify-between align-center kingSize px-4 ">
      <img
        :src="getAppLogo"
        height="30"
      >
      <div>
        <v-select
          v-model="$i18n.locale"
          :items="languages"
          item-text="name"
          item-value="id"
          :name="$t('language')"
          :label="$t('language')"
          class="text-field-transparent"
          hide-details=""
          filled
          solo
          dense
        />
      </div>
    </div>
    <v-row
      class="flex-nowrap flex-1 align-self-stretch"
      justify="center"
      align="center"
    >


      <v-col
        cols="12"
        md="4"
        style="height: 100%; "
      >
        <v-container
          class="card py-0"
          fill-height
          fluid
        >
          <v-row
            class="flex-column px-6 flex-nowrap"
            justify="center"
            align="center"
            style="height: 100%; "
          >


            <v-container class="d-flex align-center py-0 justify-center flex1">
              <v-row
                align="center"
                justify="center"
                class="flex-nowrap flex-column flex1"
              >
                <v-card
                  @click="openSelector"
                  elevation="0"
                  class="pa-3 px-4 rounded-lg card border1"
                >


                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                  >
                    <v-card
                      class="rounded-md sideBarColor elevation-2 d-flex align-center justify-center "
                      style="height: 42px; width: 42px;"
                    >
                      <img
                        v-if="!isEmpty(getTenantIcon)"
                        :src="getTenantIcon"
                        crossorigin="anonymous"
                        height="42px"
                        width="42px"
                        cover
                        class="rounded-md"
                      />
                      <div
                        v-else
                        class="text-h5 pa-4"
                      >{{ getInitial }}</div>
                    </v-card>
                    <div class="pl-3 pr-1 text-body-1 font-weight-mediumBold text-capitalize">{{ getTenant.name }}</div>


                  </v-row>

                </v-card>

                <v-bottom-sheet
                  v-model="sheet"
                  inset
                  content-class="rounded-t-xl pa-2"
                >
                  <div class="d-flex gap-1">
                    <v-list class="rounded-t-xl kingSize flex-1">
                      <v-subheader class="pl-6">Templates</v-subheader>
                      <v-list-item
                        v-for="item in getTemplates"
                        :key="item.Guid"
                        class="pl-6"
                        @click="selectTenant(item)"
                      >
                        <v-list-item-avatar><img
                            crossorigin="anonymous"
                            :src="item.logo"
                          /></v-list-item-avatar>

                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                    <v-list class="rounded-t-xl kingSize flex-1">
                      <v-subheader class="pl-6">Tenants</v-subheader>
                      <v-list-item
                        v-for="item in getTenants"
                        :key="item.Guid"
                        class="pl-6"
                        @click="selectTenant(item)"
                      >
                        <v-list-item-avatar><img
                            crossorigin="anonymous"
                            :src="item.logo"
                          /></v-list-item-avatar>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </div>

                </v-bottom-sheet>

              </v-row>
            </v-container>

            <login
              v-if="view === 'login'"
              :on-reset="showReset"
            />
            <resetPassword
              v-if="view === 'reset'"
              :on-back="showLogin"
            />
            <SetPassword
              v-if="view === 'setPass'"
              :on-back="showLogin"
            />

          </v-row>



        </v-container>
      </v-col>
      <!-- <v-col
        cols="0"
        md="8"
        class="pr-8 "
      >

        <v-container
          fill-height
          fluid
          class="tileBackgroundColor rounded-xl"
          style="position: relative; overflow: hidden;"
        >
          <div class="splash-background px-2 px-md-10">
            <img
              :src="splash"
              width="100%"
              height="100%"
            >
          </div>
          <v-row
            align="center"
            justify="center"
            style="position: relative;"
          >
            <div class="started-img-wrap">
              <div class="started-img-text d-flex align-center">
                <v-card
                  class=" elevation-2 d-flex align-center justify-center mr-2"
                  :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                  @click="openSelector"
                >
                  <img
                    v-if="!isEmpty(getTenant.logo)"
                    :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                    :src="getTenant.logo"
                    crossorigin="anonymous"
                    aspect-ratio="1"
                  />
                  <div
                    v-else
                    style="{'font-size': size}"
                  >{{ getInitial }}</div>

                </v-card>
                <div class="black--text started-workspace">{{ isEmpty(getTenant.name) ? 'Workspace'
                  :
                  getTenant.name }}</div>
              </div>
              <img
                :src="startedImg"
                height="100%"
                width="auto"
                cover
                class="started-img rounded-xl v-sheet elevation-2"
              />
            </div>
          </v-row>
        </v-container>
      </v-col> -->
    </v-row>

  </v-container>
</template>

<script>
import { APP, NODE_ENV, PRODUCTION, STAGING, TENANT } from '../../api/constants.js';
import { mapGetters, mapMutations } from "vuex";
import loadingView from "./LoadingView";
import loginBackground from "../mainComponent/LoginBackground";
require("vue2-animate/dist/vue2-animate.min.css");
import LogComponent from "./LogComponent.vue";
import login from "./Login.vue"
import resetPassword from './ResetPassword.vue'
import { isEmpty, isEmptyArray, isTrue } from '../../api/utilities.js';
import splash from '../../assets/splash-background.svg'
import startedImg from "../../assets/swiftio-started.png"
import SetPassword from './SetPassword.vue';
import { languages } from '../../api/locales/i18n';




export default {
  components: { loadingView, loginBackground, LogComponent, login, resetPassword, SetPassword },
  data() {
    return {
      APP,
      languages,
      splash,
      view: 'login',
      tenants: [],
      sheet: false,
      env: NODE_ENV,
      prodEnv: PRODUCTION,
      startedImg,
      size: '3vh',

    };
  },
  mounted() {

    if (this.$route.path === '/reset-password') {
      this.view = 'setPass'
    }
  },
  created() {
    this.fetchTenants()

  },
  computed: {
    ...mapGetters(['getTenant', 'getTenantIcon', 'isDev', 'isDark']),
    getColorEnv() {
      if (this.env === STAGING) return 'warning'
      return 'success'
    },
    getInitial() {
      if (isEmpty(this.getTenant.handle)) {
        return 'A'
      }
      return this.getTenant.handle.slice(0, 1).toUpperCase()
    },
    getTemplates() {
      return this.tenants ? this.tenants.filter(item => isTrue(item.isTemplate)) : []
    },
    getTenants() {
      return this.tenants ? this.tenants.filter(item => !isTrue(item.isTemplate)) : []
    },
    getAppLogo() {
      if (this.isDark) {
        return APP.logoDark
      } else {
        return APP.logo
      }
    }
  },
  methods: {
    ...mapMutations(['setTenant', 'switchTenant']),
    isEmpty,
    showReset() {
      this.view = 'reset'
    },
    showLogin() {
      this.view = 'login'
    },
    async fetchTenants() {
      this.tenants = await this.$store.dispatch('fetchTenants')
      if (!isEmptyArray(this.tenants)) {
        const activeTenant = this.tenants.find(item => item.handle === TENANT)
        if (activeTenant) {
          this.setTenant(activeTenant)
        }
      } else if (!isEmpty(this.tenants)) {
        this.setTenant(this.tenants)
      }
    },
    selectTenant(tenant) {
      this.switchTenant(tenant)
      this.sheet = false
    },
    openSelector() {
      if (this.isDev) {
        this.sheet = true
      }
    }
  }
};
</script>


<style scoped>
.splash-background {
  /* background-image: url('~@/assets/solar-bkg.jpg'); */
  left: 0;

  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

.started-img-wrap {
  height: 70vh;
  position: absolute;
  left: 20%;
}

.started-img-text {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1.5vh;
}

.started-workspace {
  max-width: 9vh;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.5vh;

}

.started-img {
  max-height: 100%;
}
</style>