<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <v-card
      v-show="false"
      class="ma-0 pa-0"
    >
      {{ getViewConfig.viewconfig }} {{ getIndex }}
    </v-card>
    <resize-observer @notify="onResizeComponent" />

    <v-card
      v-if="show"
      ref="mycontainer"
      :class="getCardClass"
      style="max-height:  calc(100vh - 72px); overflow-y: auto;
                                            overflow-x: hidden;"
      color="tileBackgroundColor"
    >
      <div class="mx-n3  mx-sm-n0 px-sm-6 stickyTab">
        <v-tabs
          ref="_tabs"
          :class="getTabClass"
          background-color="transparent"
          v-model="currentIndex"
          center-active
        >
          <v-tab
            v-for="tab in getTabs"
            :key="tab.tabConfig"
            :ripple="false"
            class="px-6 "
          >
            <v-hover
              v-slot="{ hover }"
              :disabled="!isConfigMode"
            >
              <v-card
                flat
                color="transparent"
              >
                <v-icon
                  v-if="getIcon(tab) !== ''"
                  :style="getStyleForIcon(tab)"
                >{{ getIcon(tab) }}</v-icon>
                {{ getTitle(tab) }}
                <v-expand-transition>
                  <div
                    v-if="hover"
                    :color="getBarColor"
                    class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                    style="height: 65px;"
                  >
                    <configurationButtons
                      :color="getBarColor"
                      :viewconfig="tab"
                      :parentconfig="tab"
                      :type="getConfigType"
                    />
                  </div>
                </v-expand-transition>
              </v-card>
            </v-hover>
          </v-tab>
          <v-hover
            v-slot="{ hover }"
            :disabled="!isConfigMode"
          >
            <div>
              <v-card
                v-if="isConfigMode"
                height="40"
                min-width="75px"
                flat
                color="transparent"
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    :color="getBarColor"
                    class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                    style="height: 65px;"
                  >
                    <configurationButtons
                      :color="getBarColor"
                      :viewconfig="getViewConfig"
                      :type="getConfigType"
                    />
                  </div>
                </v-expand-transition>
              </v-card>
            </div>
          </v-hover>
        </v-tabs>
      </div>
      <v-tabs-items
        v-model="currentIndex"
        touchless
        class="pa-0 transparent"
      >
        <v-tab-item
          v-for="(tabCard, index) in getTabs"
          :value="index"
          :key="tabCard.tabConfig"
          :transition="false"
          class="pa-0 transparent"
          style="min-height: 70vh;"
        >
          <v-card
            flat
            class="ma-0 pa-2 px-1 pa-sm-0 pb-10 pt-sm-4 transparent"
            style="min-height: 70vh;"
          >
            <configurationView
              :viewconfig="tabCard"
              :viewconfigid="tabCard.subViewConfig"
              :type="getConfigType"
            />
            <!-- <v-container
              v-if="loadedTabs[index] === false"
              fluid
              flat
              class="transparent px-4"
            >
              <v-skeleton-loader
                height="500"
                type="card"
              />
            </v-container> -->

            <viewConfigView
              v-if="loadedTabs[index] === true"
              :viewconfig="tabCard.subViewConfig"
              :object="getObject"
              class="pa-0"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";

export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      active: 1,
      show: true,
      bufferedViewConfig: "",
      currentIndex: 0,
      myListWidth: 0,
      loadedTabs: []
    };
  },
  computed: {
    /**************************
      Get Configurations
    **************************/
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getBarColor() {
      return "orange darken-2";
    },
    getViewConfig() {
      if (this.viewconfig !== this.bufferedViewConfig) {
        this.init();
      }
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getConfigType() {
      return Constants.TAB_CONFIG;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },

    getTabClass() {
      if (this.isSmallSize) return "pt-0 pt-sm-4 px-1 tileBackgroundColor";
      else {
        return "pt-0 pt-sm-4  tileBackgroundColor  "; //ma-3 pa-3
      }
    },
    getCardClass() {
      this.getIndex;
      /*
      if (this.isConfigMode === true) {
        return "no-scroll ma-0 pa-2 elevation-10";
      }*/
      return "scroll-y smoothscroll ma-0 pa-0 elevation-0";
    },
    /**************************
      Get Tabs and Indexesx
    **************************/
    getTabs() {
      var tabs = []
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.getObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.getViewConfig,
          tabs: this.getViewConfig.subViewConfig,
          size: "5",
          list: this.getMode
        };
        tabs = this.$store.getters.getTabsConfigsForViewConfig(payload);
      }
      

      this.loadedTabs = tabs.map((item, index) => {
        if (!UTIL.isEmpty(this.loadedTabs[index])) {
          return this.loadedTabs[index]
        }
        return false
      })


      return tabs;
    },
    getIndex() {
      if (this.loadedTabs[this.currentIndex] === false) {
        setTimeout(() => {
          this.setLoaded(this.currentIndex)
        }, 150)
      }
      return this.currentIndex;
    },
    getObject() {
      return this.object;
    },
    isSmallSize() {
      return this.myListWidth <= Constants.MEDIUM_SIZE_THRESHOLD
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    setLoaded(index) {
      const newArray = UTIL.copyObject(this.loadedTabs)
      newArray[index] = true
      this.loadedTabs = newArray
    },
    init() {
      this.bufferedViewConfig = this.viewconfig;
      this.currentIndex = 0;
      // this.show = false; //Needed otherwise it does not refresh well.
      // this.$nextTick(() => {
      //   this.show = true;
      // });
    },
    onResizeComponent(skip) {
      if (this.$refs._tabs) {
        this.$refs._tabs.onResize();
      }
      var container = this.$refs.mycontainer;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.$el.clientWidth;
        height = container.$el.clientHeight;
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    /**
     * Set the tabconfig based on the index.
     */
    getIcon(tab) {
      var iconActive = true;
      if (iconActive === true) {
        if (!UTIL.isEmpty(tab.icon)) {
          return tab.icon;
        }
        if (UTIL.isEmpty(tab.title)) {
          return "";
        }
        if (tab.title.toLowerCase().trim() === "list") {
          return "hi-list-bullet-outline";
        }
        if (tab.title.toLowerCase().trim() === "map") {
          return "hi-map-outline";
        }
        if (tab.title.toLowerCase().trim() === "calendar") {
          return "hi-calendar-days-outline";
        }
        if (tab.title.toLowerCase().trim() === "dashboard") {
          return "hi-chart-pie-outline";
        }
        if (tab.title.toLowerCase().trim() === "overview") {
          return "hi-rectangle-group-outline";
        }
        if (tab.title.toLowerCase().trim() === "documents") {
          return "hi-document-duplicate-outline";
        }
        if (tab.title.toLowerCase().trim() === "tasks") {
          return "hi-clipboard-document-check-outline";
        }
        if (tab.title.toLowerCase().trim() === "notes") {
          return "hi-document-text-outline";
        }
      }
      return "";
    },
    getTitle(tab) {
      // if (this.isSmallSize === true && this.getIcon(tab) !== "") {
      //   return "";
      // }
      return tab.title;
    },
    getStyleForIcon(tab) {
      if (this.getTitle(tab) !== "") {
        return "margin-right: 10px; ";
      }
      return "";
    }
  }
};
</script>
