<template>
  <v-dialog
    v-model="confirmVisible"
    max-width="400"
    content-class="rounded-lg"
  >
    <v-card>
      <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
      <v-card-text>{{ dialogText }}</v-card-text>
      <div
        class="px-6"
        v-if="confirmSelect"
      >
        <v-select
          v-model="select"
          :items="confirmSelect.options"
          filled
          solo
          :item-text="confirmSelect.itemText || 'descr'"
          :item-value="confirmSelect.itemValue || 'value'"
          class="text-field-transparent"
        ></v-select>
      </div>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          @click="handleCancel"
        >{{ cancelText }}</v-btn>
        <v-btn
          :disabled="!isEmpty(confirmSelect) && isEmpty(select)"
          :color="confirmColor"
          @click="handleConfirm"
        >{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isEmpty } from '../../api/utilities';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'confirmVisible',
      'dialogTitle',
      'dialogText',
      'cancelText',
      'confirmText',
      'confirmColor',
      'onConfirm',
      'onCancel',
      'confirmSelect'
    ]),
  },
  data() {
    return {
      select: '',
      isEmpty
    }
  },
  methods: {
    handleCancel() {
      this.$store.commit('SET_CONFIRM_VISIBLE', false);
      if (typeof this.onCancel === 'function') {
        this.onCancel();
      }
      this.$emit('cancel');
    },
    handleConfirm() {

      if (typeof this.onConfirm === 'function') {
        this.onConfirm(this.select);
      }
      this.$emit('confirm');
    },
  },
};
</script>