import { isEmpty } from "./utilities";

export const ACTIVE_TENANT = "sw_at";

export const DEVELEPMENT = "dev";
export const STAGING = "stg";
export const PRODUCTION = "production";

export const SWIFTIO = "swiftio";
export const REALTIOR = "realtior";
import swiftioLogo from "../assets/swiftio-logo-dark.svg";
import realtiorLogo from "../assets/realtior-logo-dark.svg";
import swiftioLogoDark from "../assets/swiftio-logo-light.svg";
import realtiorLogoDark from "../assets/realtior-logo-light.svg";

import swiftioImg1 from "../assets/swiftio/splash_img1.jpg";
import swiftioImg2 from "../assets/swiftio/splash_img2.jpg";
import realtiorImg1 from "../assets/realtior/splash_img1.jpg";
import realtiorImg2 from "../assets/realtior/splash_img2.jpg";

const savedTenant =
  window &&
  window.localStorage.getItem(ACTIVE_TENANT) &&
  JSON.parse(window.localStorage.getItem(ACTIVE_TENANT));
const devTenant =
  savedTenant && savedTenant.handle ? savedTenant.handle : "swiftio";
// constants/envrionment.js

let realm = SWIFTIO;
let env = DEVELEPMENT;
const url = window.location.host;
// const url = "app.swiftio.io";
const regexDomain = /^([^./]+)\./;
const match = url.match(regexDomain);

if (!isEmpty(match) && !isEmpty(match[1])) {
  realm = match[1];
  const regex = /-staging/;
  const result = realm.match(regex);
  if (result) {
    env = STAGING;
    realm = realm.replace("-staging", "");
  } else {
    env = PRODUCTION;
  }
}
if (realm === "app" || realm === "192") realm = SWIFTIO;
const NODE_ENV = env;
console.log("realm", realm, env);
// realm = realm.replace("-dev", "");

const TENANT = NODE_ENV === DEVELEPMENT ? devTenant : realm;

const BASE_URL =
  env === STAGING ? process.env.BASE_URL_STAGING : process.env.BASE_URL;
const API_BASE_URL = `${BASE_URL}/api/`;

// const API_BASE_URL = `https://api-staging.swiftio.io/api/`;

const FACUURSTUREN_BASE_URL = `${BASE_URL}/factuurSturen/`;

const AUTH_URL = `${BASE_URL}/token`;
const GOOGLE_URL = `${BASE_URL}/google`;
const apps = {
  [SWIFTIO]: {
    id: "swiftio",
    name: "Swiftio",
    handle: "swiftio.io",
    logo: swiftioLogo,
    logoDark: swiftioLogoDark,
    privacyPolicy: "https://swiftio.io/privacy-policy",
    terms: "https://swiftio.io/terms",
    signup: {
      img1: swiftioImg1,
      img2: swiftioImg2,
      appImg: "",
      info: [
        {
          title: "swiftioSplash1Title",
          subTitle: "swiftioSplash1SubTitle",
          text: "swiftioSplash1Text",
        },
      ],
    },
  },
  [REALTIOR]: {
    id: "realtior",
    name: "Realtior",
    handle: "realtior.co",
    logo: realtiorLogo,
    logoDark: realtiorLogoDark,
    privacyPolicy: "https://realtior.co/privacy-policy",
    terms: "https://realtior.co/terms",
    signup: {
      img1: realtiorImg1,
      img2: realtiorImg2,
      appImg: "",
      info: [
        {
          title: "realtorSplash1Title",
          subTitle: "realtorSplash1SubTitle",
          text: "realtorSplash1Text",
        },
      ],
    },
  },
};

const APP = getApp();

export {
  APP,
  BASE_URL,
  API_BASE_URL,
  TENANT,
  NODE_ENV,
  AUTH_URL,
  FACUURSTUREN_BASE_URL,
  GOOGLE_URL,
};

function getApp() {
  const hostname = window.location.hostname;

  if (hostname.endsWith(apps[SWIFTIO].handle)) {
    return apps[SWIFTIO];
  } else if (hostname.endsWith(apps[REALTIOR].handle)) {
    return apps[REALTIOR];
  } else {
    return apps[SWIFTIO];
    np;
  }
}
