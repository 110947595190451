import {
  copyObject,
  isEmpty,
  isEmptyObj,
  isTrue,
} from "../../api/utilities.js";
import Vue from "vue";
import { ALERT_TYPE_ERROR, VALUE_LIST_CONFIG } from "../../store/constants.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  return true;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("after create in ValueListConfig");
  if (
    !isEmptyObj(relatedObject) &&
    !isEmptyObj(relatedObject.object) &&
    !isEmpty(relatedObject.object)
  ) {
    Vue.set(object, "auth", relatedObject.object.auth);
  }

  return object;
};

/**********************************************
 * Actions
 **********************************************/

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) {
  // console.log("Can Launch action for action", object);
  if (isEmpty(object)) {
    return false;
  }
  return true;
}
