<template>
  <v-container
    ref="outerListContainerCard"
    class="pa-0 ma-0 flex-column"
    fill-height
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <div class="kingSize">
      <v-row
        flat
        class="pa-0 ma-0 flex-nowrap"
        align="center"
      >
        <v-container
          class="pa-0 ma-0"
          fluid
        >
          <filterView
            :viewconfig="viewconfig"
            :is-compact-view="isCompactView"
            :object="object"
            no-create
          >
            <template v-slot:actions>

              <slot />
            </template>

          </filterView>
        </v-container>

      </v-row>
    </div>
    <div
      class="pt-0 kingSize full-height"
      style="min-height: 80px; flex: 1;"
    >


      <listView
        v-if="isEditMode"
        :viewconfig="viewconfig"
        :selectionfunction="selectionfunction"
        :object="object"
        :height="getHeight"
        :search="getSearch"
        :list="list"
      />
      <GridView
        v-if="!isEditMode && getFilterIsActive === true"
        :viewconfig="viewconfig"
        :object="object"
        :height="getHeight"
        :header-field="getField('thumbnail')"
        :search="getSearch"
        :list="list"
      >
        <template slot="no-items">
          <v-container
            v-if="dropzoneElement"
            fill-height
            fluid
            class="full-height flex-column align-center justify-center py-8 pb-12 opaque-7"
          >
            <v-icon
              x-large
              color="primary"
              class="opaque-3 mb-4 "
            >hi-document-plus-outline</v-icon>
            <div class="text-body-2 gray--text">Drag your files here</div>
            <div class="text-caption opaque-5 gray--text">Media and PDF's supported (max 100mb)</div>

          </v-container>
        </template>
      </GridView>


    </div>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import filterView from "./FilterView";
import listViewAction from "./ListViewActions";

import GridView from "./GridView.vue";

export default {
  components: { listViewAction, filterView, GridView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    dropzoneElement: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      fields: undefined,
      searchField: "",
      myListWidth: 0,
      showFilter: true
    };
  },
  computed: {
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getCurrentFilter() {
      return this.$store.getters.getCurrentFilterForFilterId(this.getViewConfig.queryFilter)
    },
    getFilterIsActive() {
      return this.getCurrentFilter && !UTIL.isEmpty(this.getCurrentFilter.filterFields)
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined) {
        var height = parseInt(this.height, 10);
        return height - 60 + "px";
      }
      return "600px";
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    isCompactView() {
      return this.getWidth === Constants.SMALL_SIZE;
    },
    showSearchBar() {
      return !this.isCompactView;
    },
    getSearch() {
      if (!UTIL.isEmpty(this.searchField)) {
        return this.searchField;
      }
      return this.search;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    }
  },
  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    getField(header) {
      const fields = this.$store.getters.getDataObjectsForObjectType(Constants.VIEW_FIELD)
      const field = fields.find(item => (item.value === header && item.fieldList === this.getSubviewConfig))
      return field;
    },
    onResizeComponent() {
      var container = this.$refs.outerListContainerCard;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
        // if (width < 200) {
        //   return;
        // }
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    getClassOfFilters(type) {
      if (type === "spacer") {
        if (this.getWidth === Constants.SMALL_SIZE) {
          return "xs0";
        } else {
          return "";
        }
      }
      if (this.getWidth === Constants.SMALL_SIZE || this.getFilterIsActive) {
        return "xs12";
      }
      if (this.getWidth === Constants.MEDIUM_SIZE) {
        return "xs5";
      }
      if (this.getWidth === Constants.BIG_SIZE) {
        return "xs4";
      }
      return "xs3";
    }
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}
</style>
