import * as objectConfigScripts from "./objectConfigScripts";
import * as fieldConfigScripts from "./fieldConfigScripts";
import * as viewFieldScripts from "./viewFieldScripts";
import * as viewConfigScripts from "./viewConfigScripts";
import * as transportMainScripts from "./transportMainScripts";
import * as transportDomainItemScripts from "./transportDomainItemScripts";
import * as transportItemScripts from "./transportItemScripts";
import * as authGroupScripts from "./authGroupScripts";
import * as authorisationScripts from "./authorisationScripts";
import * as operationScripts from "./operationScripts";
import * as filterConfigScripts from "./filterConfigScripts";
import * as cardConfigScripts from "./cardConfigScripts";
import * as tabConfigScripts from "./tabConfigScripts";
import * as listConfigScripts from "./listConfigScripts";
import * as filterFieldConfigScripts from "./filterFieldConfigScripts";
import * as EmployeeMainScripts from "./employeeScripts";
import * as ValuelistConfigScripts from "./valueListConfigScripts";

import * as Utilities from "../../api/utilities.js";
import * as customScripts from "../customObject/customObjectScripts.js";

export const getScriptForObject = (objectType) => {
  var objectTypeScripts = objectType + "Scripts";
  switch (objectTypeScripts) {
    case "ObjectConfigScripts":
      return objectConfigScripts;
    case "FieldConfigScripts":
      return fieldConfigScripts;
    case "ViewFieldScripts":
      return viewFieldScripts;
    case "ViewConfigScripts":
      return viewConfigScripts;
    case "TransportMainScripts":
      return transportMainScripts;
    case "TransportDomainItemScripts":
      return transportDomainItemScripts;
    case "TransportItemScripts":
      return transportItemScripts;
    case "AuthGroupScripts":
      return authGroupScripts;
    case "AuthorisationScripts":
      return authorisationScripts;
    case "OperationScripts":
      return operationScripts;
    case "FilterConfigScripts":
      return filterConfigScripts;
    case "FilterFieldConfigScripts":
      return filterFieldConfigScripts;
    case "CardConfigScripts":
      return cardConfigScripts;
    case "TabConfigScripts":
      return tabConfigScripts;
    case "ListConfigScripts":
      return listConfigScripts;
    case "ValueListConfigScripts":
      return ValuelistConfigScripts;
    case "EmployeeMainScripts":
      return EmployeeMainScripts;
    default:
      return null;
  }
};

export const beforeSave = (objectConfig, object, context, options) => {
  if (Utilities.isEmpty(object.Guid)) {
    object.Guid = Utilities.getGuid();
  }

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.beforeSave)
    ) {
      return objectScripts.beforeSave(objectConfig, object, context, options);
    }
  }

  return object;
};

export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  if (Utilities.isEmpty(object.Guid)) {
    object.Guid = Utilities.getGuid();
  }

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.afterCreate)
    ) {
      return objectScripts.afterCreate(
        objectConfig,
        object,
        relatedObject,
        context,
        options,
      );
    }
  }

  return object;
};

export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.afterModify)
    ) {
      return objectScripts.afterModify(
        objectConfig,
        object,
        relatedObject,
        context,
        options,
      );
    }
  }

  return object;
};

export const getTile = (objectConfig, object, context, options) => {
  var description = "No description";
  if (
    object.description !== undefined &&
    object.description !== null &&
    object.description !== ""
  ) {
    description = object.description;
  } else {
    if (
      object.description !== undefined &&
      object.description !== null &&
      object.description !== ""
    ) {
      description = object.name;
    }
  }
  return [
    {
      text: description,
      row: 1,
    },
  ];
};

export const getFieldsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  fields,
  context,
  options,
) => {
  if (objectConfig.objectType === "ViewField") {
    return viewFieldScripts.getFieldsForObjectAndConfig(
      objectConfig,
      object,
      viewConfig,
      fields,
      context,
      options,
    );
  }
  return fields;
};

export const getTabsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  tabs,
  context,
  options,
) => {
  if (objectConfig.objectType === "ViewConfig") {
    return viewConfigScripts.getTabsForObjectAndConfig(
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options,
    );
  }
  return tabs;
};

export const hasCustomAuthorisation = (
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.hasCustomAuthorisation)
    ) {
      return objectScripts.hasCustomAuthorisation(
        objectConfig,
        object,
        viewConfig,
        context,
        options,
      );
    }
  }
  return options.hasAuthorisation;
};

export const launchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts[action])
    ) {
      return objectScripts[action](
        objectConfig,
        object,
        viewConfig,
        context,
        options,
      );
    }
  }
};

export const canLaunchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts["canLaunchActionForObjectAndConfig"])
    ) {
      return objectScripts["canLaunchActionForObjectAndConfig"](
        action,
        objectConfig,
        object,
        viewConfig,
        context,
        options,
      );
    } else {
      return true;
    }
  }
};
