// confirmDialogContext.js
export default {
  state: {
    confirmVisible: false,
    title: "Confirm Action",
    text: "Are you sure you want to proceed?",
    cancelText: "Cancel",
    confirmText: "Confirm",
    confirmColor: "primary",
    onConfirm: null,
    onCancel: null,
    select: null,
  },
  mutations: {
    SET_CONFIRM_VISIBLE(state, isVisible) {
      state.confirmVisible = isVisible;
    },
    SET_DIALOG_TITLE(state, title) {
      state.title = title;
    },
    SET_DIALOG_TEXT(state, text) {
      state.text = text;
    },
    SET_CANCEL_TEXT(state, cancelText) {
      state.cancelText = cancelText;
    },
    SET_CONFIRM_TEXT(state, confirmText) {
      state.confirmText = confirmText;
    },
    SET_CONFIRM_COLOR(state, confirmColor) {
      state.confirmColor = confirmColor;
    },
    SET_ON_CONFIRM(state, onConfirm) {
      state.onConfirm = onConfirm;
    },
    SET_ON_CANCEL(state, onCancel) {
      state.onCancel = onCancel;
    },
    SET_SELECT(state, select) {
      state.select = select;
    },
  },
  actions: {
    showConfirmDialog(
      { commit },
      {
        title,
        text,
        cancelText,
        confirmText,
        confirmColor,
        onConfirm,
        onCancel,
        select,
      },
    ) {
      commit("SET_CONFIRM_VISIBLE", true);
      commit("SET_DIALOG_TITLE", title || "Confirm Action");
      commit("SET_DIALOG_TEXT", text || "Are you sure you want to proceed?");
      commit("SET_CANCEL_TEXT", cancelText || "Cancel");
      commit("SET_CONFIRM_TEXT", confirmText || "Confirm");
      commit("SET_CONFIRM_COLOR", confirmColor || "primary");
      commit("SET_ON_CONFIRM", onConfirm);
      commit("SET_ON_CANCEL", onCancel);
      commit("SET_SELECT", select);
    },
    hideConfirmDialog({ commit }) {
      commit("SET_CONFIRM_VISIBLE", false);
      commit("SET_DIALOG_TITLE", "");
      commit("SET_DIALOG_TEXT", "");
      commit("SET_CANCEL_TEXT", "Cancel");
      commit("SET_CONFIRM_TEXT", "Confirm");
      commit("SET_CONFIRM_COLOR", "primary");
      commit("SET_ON_CONFIRM", null);
      commit("SET_ON_CANCEL", null);
      commit("SET_SELECT", null);
    },
  },
  getters: {
    confirmVisible: (state) => state.confirmVisible,
    dialogTitle: (state) => state.title,
    dialogText: (state) => state.text,
    cancelText: (state) => state.cancelText,
    confirmText: (state) => state.confirmText,
    confirmColor: (state) => state.confirmColor,
    onConfirm: (state) => state.onConfirm,
    onCancel: (state) => state.onCancel,
    confirmSelect: (state) => state.select,
  },
};
