<template>
  <v-container
    fluid
    class="transparent px-0"
  >
    <v-row>
      <v-col
        :cols="12"
        :sm="7"
      >
        <personal-info />
      </v-col>
      <!-- <v-col
        :cols="12"
        :sm="5"
      >
        <v-card
          elevation="2"
          class="no-scroll mx-0 pa-0 rounded-lg"
          color="card"
        >
          <v-card-title>Integrations</v-card-title>
          <v-card-subtitle>Gmail</v-card-subtitle>
          <v-card-actions class="px-4 pt-0 pb-4">
            <v-btn
              elevation="0"
              class="pa-4 iconButton"
              style="max-height: none;height: auto;"
              @click="authGoogle"
            > <img src="../../assets/Icons/gmail.svg"></v-btn>
          </v-card-actions>

        </v-card>
      </v-col> -->
      <!-- <v-col
        :cols="12"
        :sm="5"
      >
        <v-card
          elevation="2"
          class="no-scroll mx-0 pa-0 rounded-lg"
          color="card"
        >
          <v-card-title>Password</v-card-title>
          <v-card-subtitle>Change your password here</v-card-subtitle>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>

import { handleAuthClick } from '../../integrations/google/gmail';
import PersonalInfo from '../components/PersonalInfo.vue';


export default {
  components: {
    PersonalInfo
  },
  data() {

    return {


    };
  },
  computed: {

  },
  methods: {
    authGoogle() {
      this.$store.dispatch('signInGoogle')
    }
  }
};
</script>

<style>
/* .main {
    padding-top: 0px !important;
  } */
</style>
