<template>
  <v-card
    flat
    class="mx-0 kingSize align-center pa-0 d-flex"
    style="min-height: 24px;"
    color="transparent"
    :class="`${type} ${header.value === 'Subject' && 'subject'}`"
  >
    <span
      v-if="isDefault(header.type)"
      class="defaultInput kingSize d-flex align-center justify-start"
      @dblclick="onDoubleClick"
    >
      {{ currentValue }}
    </span>
    <div
      v-else-if="header.type === 'person'"
      class="my-0 pa-0 d-flex align-center justify-center kingSize"
      @click="clickField(header, currentObject)"
    >
      <v-tooltip
        :open-delay="100"
        content-class="rounded-lg elevation-8 lighten-5"
        bottom
        light
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            style="min-width:0px!important; width: auto; height: auto; border-radius: 6px;"
            v-on="on"
          >
            <img
              v-if="getImage(header, getItem)"
              :src="getImage(header, getItem)"
              color="gray"
              crossorigin="anonymous"
              style="width:24px!important; height: 24px;"
              alt="Avatar"
            >

            <div
              v-else
              size="24px"
              class="avatar iconButton text-caption pa-1 rounded-lg font-weight-medium"
              style="width:24px!important; height: 24px;"
              color="primary"
            >
              {{ getInitials(getLinkValue) }}
            </div>
          </v-avatar>
        </template>
        <span class="text-body-2 primaryText--text">{{ getLinkValue }}</span>

      </v-tooltip>

    </div>
    <div
      v-else-if="isLink(header)"
      class="my-0 pa-0 d-flex align-center justify-start"
      @dblclick="onDoubleClick"
    >
      <v-avatar style="min-width:0px!important; width: auto; height: auto; border-radius: 6px;">
        <img
          v-if="getImage(header, getItem)"
          :src="getImage(header, getItem)"
          class="mr-2"
          color="gray"
          crossorigin="anonymous"
          style="width:24px!important; height: 24px;"
          alt="Avatar"
        >
        <v-icon
          v-else
          size="24px"
          :class="getIcon(header) && 'mr-3'"
          color="primary"
        >
          {{ getIcon(header) }}
        </v-icon>
        <!-- <div
          v-else
          size="24px"
          :class="getIcon(header) && 'mr-2'"
          color="primary"
        >
          {{ getInitials(header.type === 'link' ? getLinkValue : currentValue) }}
        </div> -->

      </v-avatar>
      <div class="d-flex items-center">
        <a
          class="defaultInput "
          @click="clickField(header, currentObject)"
        >
          {{ header.type === 'link' ? getLinkValue : currentValue }}
        </a>
        <v-icon
          v-if="!isEmpty(currentValue)"
          size="20px"
          class="ml-2"
          color="primary"
          @click="clickField(header, currentObject, 'popup')"
        >
          hi-arrow-top-right-on-square-outline
        </v-icon>
      </div>
    </div>

    <div
      v-else-if="getTypeOfField(header) === 'actions'"
      class="kingSize"
    >
      <actionlistcell
        :header="header"
        :object="currentObject"
        :viewconfig="viewconfig"
        :is-not-list="isNotList"
      />
    </div>

    <div v-if="header.type === 'starRating'">
      <v-rating
       dense
        v-model="currentValue"
        hover
        style="margin-left: -10px"
        empty-icon="hi-star-outline"
        full-icon="hi-star"
        half-icon="mdiStarHalfFull"
        :readonly="readonly"
        :length="3"
        :size="25"
        active-color="primary"
        @change="change"
      />
    </div>

    <div
      v-else-if="header.type === 'chip'"
      class="kingSize"
      @dblclick="onDoubleClick"
    >
      <v-chip
        :class="getChipClass(header, object)"
        style="float:left;"
        :x-small="isXsmall"
        :small="!isXsmall"
      >
        {{ currentValue }}
      </v-chip>
    </div>
    <div
      v-else-if="header.type === 'chipList'"
      class="kingSize "
      @dblclick="onDoubleClick"
    >
      <v-chip
        :class="getChipClass(header, object, getValueListItems(header.dropdown))"
        style="float:left;"
        :x-small="isXsmall"
        :small="!isXsmall"
      >
        {{ getValueFromValueList(header.dropdown, currentValue) }}
      </v-chip>
    </div>
    <div
      v-else-if="header.type === 'chipListMultiple'"
      class="kingSize d-flex gap "
      @dblclick="onDoubleClick"
    >

      <v-chip
        v-for="value, index in currentValue"
        :key="index"
        :class="getChipClass(header, object, getValueListItems(header.dropdown))"
        style="float:left;"
        :x-small="isXsmall"
        :small="!isXsmall"
      >
        {{ getValueFromValueList(header.dropdown, value) }}
      </v-chip>
    </div>
    <div
      v-else-if="header.type === 'currency'"
      class="kingSize d-flex align-center justify-start"
      @dblclick="onDoubleClick"
    >
      <span class="defaultInput">
        {{ formatNumber(currentValue) }}
      </span>
    </div>
    <span
      v-if="header.type === 'phone'"
      class="defaultInput kingSize d-flex align-center justify-start"
      :class="`${isPhone(currentValue) && 'pointer'}`"
      @click="isPhone(currentValue) && call(header, currentObject)"
      @dblclick="onDoubleClick"
    >
      <v-icon
        v-if="currentValue"
        class="mr-2"
        color="gray"
      >hi-phone-outline</v-icon>
      {{ currentValue }}
    </span>
    <span
      v-if="header.type === 'email'"
      class="defaultInput kingSize d-flex align-center justify-start"
      :class="`${isEmail(currentValue) && 'pointer'}`"
      @click="isEmail(currentValue) && email(header, currentObject)"
      @dblclick="onDoubleClick"
    >
      <v-icon
        v-if="currentValue"
        class="mr-2"
        color="gray"
      >hi-envelope-outline</v-icon>
      {{ currentValue }}
    </span>
    <span
      v-if="header.type === 'location'"
      class="defaultInput kingSize d-flex align-center justify-start"
      @dblclick="onDoubleClick"
    >
      <v-icon
        v-if="currentValue"
        class="mr-2"
        color="gray"
      >hi-map-outline</v-icon>
      {{ currentValue }}
    </span>
    <span
      v-if="header.type === 'url'"
      class="defaultInput kingSize d-flex align-center justify-start"
      @dblclick="onDoubleClick"
      @click.native="goToUrl()"
    >
      <v-icon
        v-if="currentValue"
        class="mr-2"
        color="gray"
      >hi-link-outline</v-icon>
      {{ currentValue }}
    </span>

    <div
      v-else-if="getTypeOfField(header) === 'valueList'"
      class="defaultInput kingSize d-flex align-center justify-start"
      @dblclick="onDoubleClick"
    >
      {{ getValueFromValueList(header.dropdown, currentValue) }}
    </div>
    <div
      v-else-if="header.type === 'relativeDate'"
      class="kingSize"
    >
      <v-row
        v-if="currentValue"
        no-gutters
        align="center"
        justify="start"
        @dblclick="onDoubleClick"
      >

        <v-tooltip
          :open-delay="300"
          content-class="rounded-xl elevation-8 lighten-5"
          bottom
          light
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-row
              v-bind="attrs"
              align="center"
              class="text-caption ma-0 flex-nowrap text-left text-no-wrap"
              v-on="on"
            >
              <v-icon
                class="mr-2"
                color="gray"
              >hi-calendar-days-outline</v-icon>
              {{ getCalendar(currentValue) }}</v-row>
          </template>
          <span class="text-body-2 primaryText--text">{{ getDate(currentValue) }}</span>
        </v-tooltip>
      </v-row>

    </div>
    <div
      v-else-if="header.type === 'relativeDateChip'"
      class="kingSize"
    >
      <v-tooltip
        :open-delay="300"
        content-class="rounded-lg elevation-8"
        bottom
        light
        color="card"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-row
            v-bind="attrs"
            v-if="currentValue"
            no-gutters
            align="center"
            justify="start"
            @dblclick="onDoubleClick"
            v-on="on"
          >
            <v-chip
              v-if="getUrgency(currentValue) !== null"
              :color="getUrgency(currentValue)"
              :x-small="isXsmall"
              :small="!isXsmall"
            >
              <v-icon
                small
                class="mr-2"
                color="white"
              >hi-calendar-days-outline
              </v-icon>
              {{ getUrgency(currentValue) === 'warning' ? 'Today' : getRelative(currentValue)
              }}
            </v-chip>
            <v-row
              v-bind="attrs"
              v-else
              align="center"
              class="text-caption ma-0 flex-nowrap text-left text-no-wrap"
              v-on="on"
            >
              <v-icon
                class="mr-2"
                color="gray"
              >hi-calendar-days-outline</v-icon>
              {{ getDueDate(currentValue) | moment("from", "now") }}</v-row>
          </v-row>
        </template>
        <span class="text-body-2 primaryText--text">{{ getDate(currentValue) }}</span>
      </v-tooltip>
    </div>
    <div
      v-else-if="header.type === 'dateSelect'"
      class="d-flex align-center"
      @dblclick="onDoubleClick"
    >
      <v-icon
        class="mr-2"
        color="gray"
      >hi-calendar-days-outline</v-icon>
      <span class="defaultInput"> {{ formatValue(currentValue) }}</span>
    </div>
    <div
      v-else-if="header.type === 'timeSelect'"
      class="d-flex align-center"
      @dblclick="onDoubleClick"
    >
      <v-icon
        class="mr-2"
        color="gray"
      >
        hi-clock-outline
      </v-icon>
      <span class="defaultInput"> {{ currentValue }} </span>

    </div>
    <div
      v-else-if="header.type === 'progressBar'"
      class="kingSize"
    >
      <v-progress-linear
        :value="currentValue"
        :readonly="true"
        background-color="tileBackgroundColor"
        rounded
        height="4"
        color="primary"
        @change="change"
      />
    </div>
    <div
      v-else-if="header.type === 'image'"
      class="kingSize"
    >
      <v-card
        :class="getImageClass"
        flat
        color="transparent"
      >
        <v-avatar
          class="elevation-2"
          style="width: 100%; height:100%; aspect-ratio:1; border-radius:10px;"
          @click="openImagePreviewModel"
        >

          <img
            v-if="!isEmpty(getImageType) && !getImageType.includes('image/')"
            :src="getTypeIcon(getImageType)"
            alt="pdf-icon"
            class="pa-2"
          />
          <img
            v-else-if="getImage(header, object)"
            :src="getFullImage(header, object)"
            crossorigin="anonymous"
            class="avatarImg"
            style="align:center; object-fit: cover;"
            alt="Avatar"
          >
        </v-avatar>
      </v-card>
      <v-dialog
        v-model="imagePreviewModal"
        content-class="rounded-lg w-auto relative"
      >

        <div class="absolute z50 kingSize d-flex full-height items-center justify-between no-pointer">

          <v-btn
            class="overlay-btn mx-2 all-pointer"
            large
            icon
            @click="prevAttachment"
          >
            <v-icon class="configLight text-primary">hi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            class="overlay-btn mx-2 all-pointer"
            large
            icon
            @click="nextAttachment"
          >
            <v-icon class="configLight text-primary">hi-chevron-right</v-icon>
          </v-btn>

        </div>
        <v-card
          v-if="getImageType === 'application/pdf'"
          color="card"
          style="height:90vh; width: 90vw;"
        >
          <div class="iframe-loader">
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>
          <iframe
            v-if="pdfDataUrl"
            :src="`${pdfDataUrl}`"
            height="100%"
            width="100%"
            style="border:none; position: relative; z-index: 1;"
          />
        </v-card>
        <v-card
          v-else
          flat
          color="card"
          class="d-flex"
        >
          <img
            :src="currentAttachment"
            crossorigin="anonymous"
            style="max-height:90vh; max-width:100%; object-fit: contain;"
          >

        </v-card>
      </v-dialog>
    </div>
    <div
      v-else-if="header.type === 'switch'"
      class="kingSize"
      style="max-width: 50px; "
    >
      <v-switch
        style="margin-top: -5px"
        v-model="currentValue"
        :label="getLabel(header)"
        :disabled="false"
        :readonly="readonly"
        :dense="dense"
        :filled="filled"
        :solo="solo"
        :hide-details="hidedetails"
        inset
        class="v-input--reverse"
        @change="change"
      />
    </div>
    <div
      v-else-if="header.type === 'checkbox'"
      class="kingSize"
    >
      <v-checkbox
        v-model="currentValue"
        :label="getLabel(header)"
        :disabled="readonly"
        :dense="dense"
        :hide-details="hidedetails"
        @change="change"
      />
    </div>

    <div
      v-else-if="header.type === 'percentageBar'"
      class="kingSize"
    >
      <v-row
        align="center"
        no-gutters
      >
        <span class="text-subtitle">{{ roundNumber(isEmpty(currentValue) ? 0 : currentValue * 100) }}%</span>
        <v-progress-linear
          :value="isEmpty(currentValue) ? 0 : currentValue * 100"
          :readonly="true"
          :color="currentValue >= 1 ? 'success' : 'quinary'"
          background-color="tileBackgroundColor"
          rounded
          height="4"
        />
      </v-row>
    </div>
    <div
      v-else-if="getTypeOfField(header) === 'title'"
      class="kingSize"
    >
      <h3>{{ header.text }}</h3>
    </div>
    <div
      v-else-if="header.type === 'multipleInput'"
      class="kingSize"
    >
      {{ currentValue }}
    </div>
    <div
      v-else-if="header.type === 'textEdit'"
      class="defaultInput kingSize"
    >
      {{ getTextFromHtml(currentValue) }}
    </div>
  </v-card>
</template>

<script>
import * as Constants from "../../store/constants.js";
import listSelectionView from "../splitCard/ListView";
import textEditor from "./textEditor";
import { isEmpty, formatNumber, getTimesOptions, isPhone, isEmail, roundNumber, isValidHttpUrl, isBase64, isBlob, isNumeric, isEmptyArray } from "../../api/utilities.js";
import multipleInput from "./MultipleInput";
import pdfIcon from "../../assets/Icons/types/pdf.svg"
import JpgIcon from "../../assets/Icons/types/jpg.svg"
import zipIcon from "../../assets/Icons/types/zip.svg"
import excelIcon from "../../assets/Icons/types/excel.svg"
import xlsIcon from "../../assets/Icons/types/xls.svg"
import wordIcon from "../../assets/Icons/types/word.svg"
import txtIcon from "../../assets/Icons/types/text.svg"
import actionlistcell from "./ListCells/ActionListCell"
import moment from "moment";
import Axios from 'axios';
import { translateHTML } from "../../api/stringLib";
import { prepareFilter } from '../../api/dynamicFilter.js';

export default {
  components: { listSelectionView, textEditor, multipleInput, actionlistcell },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    keyobject: {
      type: Object,
      default: () => ({})
    },
    operationid: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: true
    },
    filterfunction: {
      type: Function,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    showlabel: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    solo: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hidedetails: {
      type: String,
      default: "false"
    },
    navigateidlink: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left"
    },
    isNotList: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    appendText: {
      type: String,
      default: ""
    },
    prependText: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    step: {
      type: Number,
      default: null
    },
    images: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      refreshKey: 0,
      dialog: false,
      viewConfigOfSelection: null,
      selectionFunction: null,
      selectedField: null,
      pdfIcon,
      JpgIcon,
      zipIcon,
      excelIcon,
      wordIcon,
      txtIcon,
      xlsIcon,
      valueSelect: false,
      imagePreviewModal: false,
      isAutofocus: false,
      ALERT_TYPE_WARNING: Constants.ALERT_TYPE_WARNING,
      ALERT_TYPE_ERROR: Constants.ALERT_TYPE_ERROR,
      pdfData: null,
      pdfDataUrl: null,
      currentAttachment: null
    };
  },
  computed: {
    isXsmall() {
      if (this.type === 'kanban') {
        return true
      }
      return false
    },
    getImageClass() {
      if (this.align === "center") {
        return "pa-0 centerImage";
      }
      return " pa-0 ";
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    currentValue: {
      get() {
        return this.currentObject[this.header.value];
      },
      set(value) {
        this.currentObject[this.header.value] = value;
        if (this.operationid) {
          var payload = { operationId: this.operationid, field: this.header.value, value: this.currentObject }
          this.$store.dispatch("updateValueForFieldForOperationId", payload)
        }
      }
    },
    getLinkValue() {
      const filteredObjects = this.$store.getters.getDataObjectsForObjectType(
        this.header.relatedObjectType
      );
      if (filteredObjects) {
        const object = filteredObjects.find(item => item[this.getValueRelatedObject(this.header)] === this.currentValue)
        if (object) {
          return object[this.getDisplayRelatedObject(this.header)]
        }
      }
      return ''
    },
    currentObject() {
      this.refreshKey;
      if (!isEmpty(this.operationid)) {
        var operationContainer = this.$store.getters.getOperationContainerForId(
          this.operationid
        );
        if (operationContainer && operationContainer.newObject) {
          if (operationContainer.newObject[this.header.value] === undefined) {
            operationContainer.newObject[this.header.value] = null;
            if (this.header.type === 'amount') {
              operationContainer.newObject[this.header.value] = 0;
            }
          }
          return this.$store.getters.getOperationContainerForId(this.operationid).newObject
        }
      }
      return this.object;
    },
    getItem() {
      const filteredObjects = this.$store.getters.getDataObjectsForObjectType(
        this.header.relatedObjectType
      );
      if (filteredObjects) {
        return filteredObjects.find(item => item[this.getValueRelatedObject(this.header)] === this.currentValue)
      }
      return null
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getImageType() {
      if (this.currentObject) {
        if (this.currentObject.attachmentType) return this.currentObject.attachmentType
        if (this.currentObject.type) return this.currentObject.type
      }
      return null
    },
  },
  mounted() {
    if (this.getImageType === 'application/pdf') {
      this.getPdf(this.header, this.currentObject)
    }

  },
  methods: {
    formatNumber,
    getTimesOptions,
    roundNumber,
    isEmpty,
    isEmail,
    isPhone,
    getTypeIcon(type) {
      console.log(type)
      if (type.includes('pdf')) {
        return pdfIcon
      }
      if (type === 'text/csv') {
        return excelIcon
      }
      if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return xlsIcon
      }
      if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return wordIcon
      }
      if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return wordIcon
      }
      if (type === 'text/plain') {
        return txtIcon
      }
      if (type.includes('image')) {
        return JpgIcon
      }
      return JpgIcon
    },
    prevAttachment() {
      this.index = this.images[this.index - 1] ? this.index - 1 : this.images.length - 1
      const object = this.images[this.index]
      if (object) {

        this.currentAttachment = this.getFullImage(this.header, object) ? this.getFullImage(this.header, object) : this.getImage(this.header, object)
      }
    },
    nextAttachment() {
      this.index = this.images[this.index + 1] ? this.index + 1 : 0
      const object = this.images[this.index]
      if (object) {
        this.currentAttachment = this.getFullImage(this.header, object) ? this.getFullImage(this.header, object) : this.getImage(this.header, object)
      }
    },
    call(field, currentObject) {
      window.open(`tel:${this.currentValue}`);
    },
    email(field, currentObject) {
      window.open(`mailTo:${this.currentValue}`);
    },
    goToUrl() {
      if (isEmpty(this.currentValue)) {
        return
      }
      window.open(`${this.currentValue}`)
    },
    isDefault(type) {
      return type === 'input' || type === 'amount' || type == 'display' || type == 'notes' || type == 'HtmlEditor'
    },
    onDoubleClick() {
      if (!this.readonly) {
        return
      }
      if (!this.isNotList) {
        this.$emit('onDoubleClick')
      } else {
        this.operationId = this.$store.getters.generateOperationId;
        var config = this.$store.getters.getObjectConfigForType(
          this.getObjectType
        );

        var payload = {
          object: this.currentObject,
          config: config,
          id: this.operationId
        };
        this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);
        var payload = {
          viewconfig: this.viewconfig,
          operationId: this.operationId
        };
        this.$store.commit("changeEditMode", payload);
        this.isAutofocus = true
        setTimeout(this.disableAutofocus, 300)
      }

    },
    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    drag(ev) {
      const data = {
        fileName: this.currentObject.name,
        data: this.pdfData
      }
      if (data) {
        const stringData = JSON.stringify(data)
        ev.dataTransfer.setData("PDF", stringData);
      }
    },
    disableAutofocus() {
      this.isAutofocus = false
    },
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getChipClass(header, object, dropdown) {
      if (header && object && dropdown) {
        const value = object[header.value]
        const listItem = dropdown.find(item => item.value === value)
        if (listItem) {
          return `${listItem.color} white--text`
        }
      }
      return ''
    },
    getInitials(name) {
      if (name) {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
          (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials
      }
    },
    change(value) {
      this.$emit('change');
      if (this.getViewConfig && this.getViewConfig.objectType) {
        var objectConfig = this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
        if (objectConfig) {
          objectConfig.afterModify(this.currentObject, null, this.$store);
        }
      }

    },
    formatValue(value) {
      if (!isEmpty(value)) {
        return this.$moment(value).format('DD/MM/YYYY');
      }
      return ''
    },
    getClass(header) {
      var cssClass = "text-field-transparent";
      if (this.align === "right") {
        cssClass = cssClass + " alignRight";
      }
      if (this.isLink(header)) {
        cssClass = cssClass + " link primary--input";
      }
      if (header.type === 'amount') {
        cssClass += " amount";
      }
      if (header.type === 'dateSelect') {
        if (!this.readonly) cssClass += " date-select";

      }
      if (this.noBorder) {
        cssClass += " no-border";
      }
      return cssClass;
    },
    getLabel(header) {
      if (this.showlabel === true) {
        return header.text;
      }
      return "";
    },
    isLink(field) {
      if (field.type === "link") {
        return true;
      }
      if ((field.type === "idLink" || field.type === "person") && this.navigateidlink === true) {
        return true;
      }
      return false;
    },
    getTypeOfField(field) {
      if (field.type === "link" && !isEmpty(field.dropdown)) {
        return "valueList";
      }
      return field.type;
    },
    getIcon(field) {
      if (field && !isEmpty(field.icon)) return field.icon
      return null;
    },
    hasImage(field, object) {
      return !isEmpty(this.getImage(field, object))
    },
    async getPdf(field, object) {
      const pdfUrl = this.getFullImage(field, object)
      if (pdfUrl) {
        if (isBlob(pdfUrl)) {
          this.pdfDataUrl = pdfUrl
        }
        if (isValidHttpUrl(object[field.image])) {
          const response = await Axios.get(pdfUrl, {
            responseType: 'blob',
          })
          if (response && response.data) {

            this.pdfDataUrl = URL.createObjectURL(response.data)
            this.pdfData = await this.blobToBase64(response.data)
          }
        }
      }
    },
    getImage(field, object) {
      if (
        (object && (field.image !== undefined && field.image !== null) ||
          field.type === "image")
      ) {
        if (object.thumbnail) {
          if (isBase64(object.thumbnail)) {
            return object.thumbnail
          }
        } else if (object[field.image]) {
          if (isValidHttpUrl(object[field.image])) {
            return object[field.image]
          }
        } else if (object.image) {
          if (isValidHttpUrl(object.image)) {
            return object.image
          }
        }
        else if (object.url) {
          if (isValidHttpUrl(object.url)) {
            return object.url
          }
        }
      }
      return null;
    },
    getFullImage(field, object) {

      if (field && field.image && field.type === "image" && object[field.image]) {
        return object[field.image]
      }
      if (object.file && object.file !== 'file') {
        if (object.attachmentType === 'application/pdf') {
          var fileURL = this.convertBase64ToBlob(object.file);
          return fileURL
        }
        return object.file
      }
      return null;
    },
    convertBase64ToBlob(dataURI) {
      var byteString = atob(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      var URL = window.URL || window.webkitURL;
      return URL.createObjectURL(blob);
    },
    getDisplayRelatedObject(field) {
      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== null
      ) {
        return field.displayRelatedObject;
      }
      return "text";
    },
    getValueRelatedObject(field) {
      if (
        field.valueRelatedObject !== undefined &&
        field.valueRelatedObject !== null
      ) {
        return field.valueRelatedObject;
      }
      return "Guid";
    },
    getUrgency(date) {
      if (isEmpty(this.header.selectionView)) {
        return null
      } else {
        try {
          const filter = JSON.parse(this.header.selectionView)
          const preparedFilter = prepareFilter(filter, this.$store.getters)
          const f = new Function("item", " return " + preparedFilter + ";");
          const filtered = [this.object].filter(f)
          if (isEmptyArray(filtered)) {
            return null
          }
        } catch (error) {
          console.log(error)
        }
      }
      const dueDate = this.getDueDate(date)
      const today = moment().endOf('day').format(Constants.DATE_FORMAT)
      if (moment(dueDate, Constants.DATE_FORMAT).isAfter(today, 'day')) {
        return null
      } else if (moment(dueDate, Constants.DATE_FORMAT).isSame(today, 'day')) {
        return Constants.ALERT_TYPE_WARNING
      } else {
        return Constants.ALERT_TYPE_ERROR
      }
    },
    getDueDate(date) {
      return moment(date).endOf('day')
    },
    getRelative(date) {
      return this.getDueDate(date).fromNow()
    },
    getCalendar(date) {
      const twoWeeks = moment().subtract(1, 'weeks')
      let newDate = date
      if (isNumeric(date)) {
        newDate = Number(date)
      }
      if (moment(newDate).isBefore(twoWeeks)) {
        return moment(newDate).fromNow()
      }
      return moment(newDate).calendar()
    },
    getDate(date) {
      return moment(date).format('MMM Do YYYY [at] HH:mm')
    },
    getValue(field, object) {
      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== "" &&
        field.displayRelatedObject !== null
      ) {
        var payload = {
          objectType: field.relatedObjectType,
          objectId: object[field.value]
        };
        var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        if (relatedObject !== undefined) {
          //Split
          return splitFields(relatedObject, field.displayRelatedObject);
        } else {
          return "";
        }
      }
      if (field.type === "date") {
        var date = object[field.value];
        return jsonDateToString(date);
      }
      return object[field.value];
    },
    getValueListItems(listCode) {
      let list = this.$store.getters.getValueListForId(listCode)
      return list
      /*
      if (!this.header.isMandatory && Array.isArray(list)) {
        list.unshift({ value: null, descr: '' })
      }
      return list;
      */
    },
    getValueFromValueList(listCode, value) {
      let list = this.$store.getters.getValueListForId(listCode)
      if (value && Array.isArray(list)) {
        const item = list.find(item => item.value === value)
        if (item) {
          return item.descr
        }
      }
      return value;
    },
    getTextFromHtml(value) {
      return translateHTML(value)
    },
    clickField: async function (field, object, popup) {
      this.refreshKey++;
      if (!this.isLink(field)) {
        return;
      }
      if (!this.readonly) {
        return;
      }
      if (isEmpty(object[field.value])) {
        return;
      }

      if (
        this.selectionfunction !== null &&
        this.selectionfunction !== undefined
      ) {
        this.selectionfunction(object);
        return;
      }
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var valueRelated = 'Guid'
        if (!isEmpty(field.valueRelatedObject)) {
          valueRelated = field.valueRelatedObject
        }
        var filter = (item) => item[valueRelated] === navigationId
        var navigationObject = this.$store.getters.getObjectForFilter(
          field.relatedObjectType,
          filter
        );
        if (isEmpty(navigationObject)) {
          this.$store.dispatch("showLoading");
          navigationObject = await this.$store.dispatch('fetchObject', {
            objectType: field.relatedObjectType,
            objectGuid: navigationId,
            guidField: field.valueRelatedObject || 'Guid'
          })
          this.$store.dispatch("hideLoading");
        }
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      if (popup === 'popup') {
        this.$store.dispatch("showViewDialog", payload);
      } else {
        this.$store.dispatch(Constants.SELECT_OBJECT, payload);
      }
    },

    openImagePreviewModel() {
      if (isEmpty(this.getImageType) || this.getImageType.includes('image/') || this.getImageType.includes('pdf')) {
        this.imagePreviewModal = true
        this.getPdf(this.header, this.currentObject)
        this.currentAttachment = this.getFullImage(this.header, this.object) ? this.getFullImage(this.header, this.object) : this.getImage(this.header, this.object)
      } else {
        window.open(this.object.url, "_blank");
        return
      }
      window.addEventListener('keydown', (e) => {
        if (this.imagePreviewModal === true) {
          if (e.key == 'ArrowLeft') {
            this.prevAttachment()
          } else if (e.key == 'ArrowRight') {
            this.nextAttachment()
          }
        }
      });



    },
  }
};
</script>


<style scoped>
.fixrow {
  margin-top: -4px;
  margin: 0px;
}

.centerImage {
  text-align: center;
}

.defaultInput {
  white-space: nowrap;
  /* opacity: .87; */
  font-size: 13px;
  font-weight: 400;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.defaultInput.link {
  color: var(--v-primaryText-base)
}

.kanban .defaultInput {
  white-space: normal;
  font-size: 13px;
  font-weight: 600;
}

.kanban.subject .defaultInput {
  color: var(--v-primaryText-base)
}

.kanban {
  overflow: hidden;
}

.pdfPreview {
  width: 105%;
  height: 105%;
  position: absolute;
  opacity: .7;
  top: -1px;
  left: -1px;
  z-index: 1;
}

.pdf {
  width: 60%;
  height: 60%;
  position: relative;
  z-index: 15;
  /* margin-top: 5%; */
}
</style>
