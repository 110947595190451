<template>
  <v-card
    flat
    color="white"
    class="ma-3 pa-3"
  >

    <v-container>
      <v-row justify="start">
        <google-drive-auth />

      </v-row>
    </v-container>
  </v-card>
</template>

<script>

import GoogleDriveAuth from './GoogleDriveAuth.vue';

export default {
  components: { GoogleDriveAuth }
}
</script>
